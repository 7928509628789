import React, { useState, useEffect } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Grid,
  InputLabel,
  Box,
  Typography,
} from "@mui/material";
import axiosServices from "../../services/axios";
import QuestionList from "./question-list";
import SelectedQuestion from "./selected-question";
import Loader from "../../components/loader";

const Questions = () => {
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [chapters, setChapters] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState("");
  const [topics, setTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState("");
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [noQuestions, setNoQuestions] = useState(false);
  const [assessments, setAssessments] = useState();

  useEffect(() => {
    // Fetch subjects on component mount
    const fetchSubjects = async () => {
      setIsLoading(true);
      try {
        const response = await axiosServices.get("/subjects");
        setSubjects(response.data);
      } catch (error) {
        console.error("Error fetching subjects:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSubjects();
  }, []);

  const reloadAllQuestions = () => {
    handleTopicChange({ target: { value: selectedTopic } });
  };

  const handleSubjectChange = async (event) => {
    setSelectedChapter("");
    setSelectedTopic("");
    setQuestions([]);
    setNoQuestions(false);
    localStorage.setItem(
      "selectedSubject",
      JSON.stringify(subjects.find((sub) => sub.id == event.target.value))
    );
    const selectedSubject = event.target.value;
    setSelectedSubject(selectedSubject);

    // Fetch chapters based on selected subject
    try {
      setIsLoading(true);
      const response = await axiosServices.get(
        `/chapters?subjectId=${selectedSubject}`
      );
      let newChapters =
        response.data &&
        response.data.length &&
        response.data.sort((a, b) => {
          return a.displayOrder - b.displayOrder;
        });
      setChapters(newChapters);
    } catch (error) {
      console.error("Error fetching chapters:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChapterChange = async (event) => {
    setSelectedTopic("");
    setQuestions([]);
    setNoQuestions(false);
    const selectedChapter = event.target.value;
    setSelectedChapter(selectedChapter);

    // Fetch topics based on selected chapter
    try {
      setIsLoading(true);
      const response = await axiosServices.get(
        `/topics?chapterId=${selectedChapter}`
      );
      let newTopics =
        response.data &&
        response.data.length &&
        response.data.sort((a, b) => {
          return a.displayOrder - b.displayOrder;
        });
      let allTopics = {
        id: 0,
        name: "All",
      };

      setTopics([allTopics, ...newTopics]);
    } catch (error) {
      console.error("Error fetching topics:", error);
    } finally {
      setIsLoading(false);
    }

    // Fetch assessments in this chapter
    try {
      setIsLoading(true);
      const response = await axiosServices.get(
        `/assessments?chapterId=${selectedChapter}`
      );
      if (response.data.length) {
        response.data.forEach((item) => {
          item.color = `#${Math.floor(Math.random() * 0xffffff + 0x808080)
            .toString(16)
            .slice(0, 6)}`;
        });
      }
      setAssessments(response.data);
    } catch (error) {
      console.error("Error fetching topics:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTopicChange = async (event) => {
    const selectedTopic = event.target.value;
    setSelectedTopic(selectedTopic);

    // Fetch questions based on selected topic
    try {
      setIsLoading(true);
      const response = await axiosServices.get(
        `/questions/${selectedSubject}/${selectedChapter}/${selectedTopic}`
      );
      if (response.data.length == 0) {
        setNoQuestions(true);
      }
      let newQuestions =
        response.data &&
        response.data.length &&
        response.data.sort((a, b) => {
          const assessmentIdA =
            a.Assessments.length > 0 ? a.Assessments[0].id : 0; // hypothetical assessmentId
          const assessmentIdB =
            b.Assessments.length > 0 ? b.Assessments[0].id : 0;

          return assessmentIdA - assessmentIdB;
        });
      setQuestions(newQuestions);
    } catch (error) {
      console.error("Error fetching questions:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleQuestionSelection = (question) => {
    setSelectedQuestion(question);
  };

  const getAIContextForSubject = () => {
    const aiContext = subjects.find(
      (sub) => sub.id == selectedSubject
    ).AIContext;
    return aiContext;
  };

  return (
    <>
      {isLoading && <Loader />}
      <Grid container spacing={2}>
        <Grid item>
          <Box sx={{ minWidth: 200 }}>
            <FormControl fullWidth>
              <InputLabel id="subject-select-label">Subject</InputLabel>
              <Select
                labelId="subject-select-label"
                id="subject-select"
                value={selectedSubject}
                label="Subject"
                onChange={handleSubjectChange}
              >
                {subjects?.map((subject) => {
                  return (
                    <MenuItem key={subject.id} value={subject.id}>
                      {subject.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        {selectedSubject && (
          <Grid item>
            <Box sx={{ minWidth: 200 }}>
              <FormControl fullWidth>
                <InputLabel id="chapter-select-label">Chapter</InputLabel>
                <Select
                  labelId="chapter-select-label"
                  id="chapter-select"
                  value={selectedChapter}
                  label="Chapter"
                  onChange={handleChapterChange}
                >
                  {chapters?.map((chapter) => {
                    return (
                      <MenuItem key={chapter.id} value={chapter.id}>
                        {chapter.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        )}
        {selectedChapter && (
          <Grid item>
            <Box sx={{ minWidth: 200 }}>
              <FormControl fullWidth>
                <InputLabel id="topic-select-label">Topic</InputLabel>
                <Select
                  labelId="topic-select-label"
                  id="topic-select"
                  value={selectedTopic}
                  label="Topic"
                  onChange={handleTopicChange}
                >
                  {topics?.map((topic) => {
                    return (
                      <MenuItem key={topic.id} value={topic.id}>
                        {topic.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        )}
      </Grid>
      {questions?.length > 0 ? (
        <Grid container mt={2}>
          <Grid item xs={3.5} style={{ borderRight: "1px solid #eee" }} pr={3}>
            <QuestionList
              questions={questions}
              questionSelected={handleQuestionSelection}
              assessments={assessments}
              selectedSubject={selectedSubject}
            />
          </Grid>
          <Grid item xs={8.5}>
            <SelectedQuestion
              selectedQuestion={selectedQuestion}
              selectedSubjectId={selectedSubject}
              refreshQuestions={reloadAllQuestions}
              topics={topics}
              aiContext={getAIContextForSubject()}
              assessments={assessments}
            />
          </Grid>
        </Grid>
      ) : (
        <>
          {noQuestions && (
            <Typography variant="h6">No questions found</Typography>
          )}
        </>
      )}
    </>
  );
};

export default Questions;
